import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/static-page.js";
import { WorkServices } from "../components/work-services/work-services";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Work`}</h1>
    <p>{`I’m the kind of software engineer who got into the craft organically, by tinkering and bolting together rickety scripts and apps in the margins of other jobs.`}</p>
    <p>{`Now, I’m an experienced developer who’s contributed to open source software, mentored junior engineers, and built apps for well-known brands at enterprise scale.`}</p>
    <p>{`With a background in digital marketing and technical documentation, I approach every project with perspective that goes beyond code—I care a lot about being a good web citizen and creating thoughtful user experiences.`}</p>
    <h2>{`Work with me`}</h2>
    <p>{`I’m available for limited freelance work, especially if you have a project that’s related to climate or urban justice.`}</p>
    <h3>{`Services`}</h3>
    <WorkServices mdxType="WorkServices" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      